import React, { useState } from 'react';
import { FormMode } from 'utils/types';
import { SetItemsSelectionFormFooterProps } from 'pages/shared/setItemsSelectionForm/setItemsSelectionFormFooter/SetItemsSelectionFormFooter.consts';
import Error from 'components/shared/error/Error';
import { ButtonContained, NewButtonOutlined, ButtonText } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import { ButtonsContainer, CancelButton, Footer, Header, SaveButton, DotsIcon, DropdownMenu, DotsContainer, StyledDotsIcon } from './SetItemsSelectionFormFooter.style';
import NewTooltip from 'components/shared/tooltip/NewTooltip';
import { SetItemsSelectionFormHeaderProps } from './SetItemsSelectionFormHeaderconsts';
import { ModalHeader } from 'components/shared/modal/Modal.style';
import { handleKeyPress } from 'utils/keyPress';

const DELETE_TOOLTIP_ID = '#delete-tooltip';

const SetItemsSelectionFormHeader = ({
  onSave,
  formMode,
  onDelete,
  onDuplicate,
  onViewUsage,
  onEdit,
  onClose,
  formState,
  isReadOnly = false,
  isNonProduct = false,
}: SetItemsSelectionFormHeaderProps) => {
  const { isDirty, isValid, errors } = formState;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleAction = (actionFn: () => void) => {
    setButtonsDisabled(true);
    try {
      actionFn();
    } finally {
      setButtonsDisabled(false);
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const handleDelete = (actionFn: () => void) => {
    if (!isDeleteButtonClicked) {
      setIsDeleteButtonClicked(true);
      actionFn();
      hideTooltip(DELETE_TOOLTIP_ID);
    }
  };

  const GeneralButtons = (
    <>
      {isDirty && (
        <NewTooltip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => hideTooltip('#cancel-tooltip')}
          onApproveClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}
        />
      )}
      <CancelButton
        data-tip
        data-for="cancel-tooltip"
        onClick={() => (!isDirty ? (onClose ? onClose() : store.dispatch(closeModal())) : null)}
      >
        Cancel
      </CancelButton>
      <SaveButton disabled={!isDirty || !isValid || buttonsDisabled} onClick={() => handleAction(onSave)}>
        Save
      </SaveButton>
    </>
  );

  const FormSectionToComponent: Record<FormMode, JSX.Element> = {
    [FormMode.View]: (
      <DotsContainer isVisible={isDropdownOpen}>
        <DotsIcon onClick={toggleDropdown} tabIndex={0} role={"button"} aria-label={"Menu"}
          onKeyDown={(event) => handleKeyPress(event, toggleDropdown)}> <StyledDotsIcon name="Vector"></StyledDotsIcon></DotsIcon>
        {isDropdownOpen && (
          <DropdownMenu>
            <NewButtonOutlined
              data-tip
              data-for="delete-tooltip"
              disabled={buttonsDisabled || isReadOnly}
              onClick={() => null}
              hide={isNonProduct}
            >
              Delete
            </NewButtonOutlined>
            <Tooltip
              id="delete-tooltip"
              content="Are you sure you want to delete?"
              approveMsg="Yes, Delete"
              onDisapproveClick={() => {
                hideTooltip(DELETE_TOOLTIP_ID);
              }}
              onApproveClick={() => {
                hideTooltip(DELETE_TOOLTIP_ID);
                handleDelete(onDelete)
              }
              }
            />
            <NewButtonOutlined disabled={buttonsDisabled} onClick={onDuplicate} hide={isNonProduct}>
              Duplicate
            </NewButtonOutlined>
            <NewButtonOutlined disabled={buttonsDisabled} onClick={onViewUsage}>
              View Usage
            </NewButtonOutlined>
            
          </DropdownMenu>
        )}
      </DotsContainer>
    ),
    [FormMode.New]: GeneralButtons,
    [FormMode.Select]: GeneralButtons,
    [FormMode.Edit]: GeneralButtons,
    [FormMode.Duplicate]: GeneralButtons,
    [FormMode.SelectionView]:null,
    [FormMode.QuickView]: null,
  };

  return (
    
    <Header>
      <div>
        {Object.keys(errors)
          .filter((field) => field !== 'name')
          .map((errorKey) => (
            <Error name={errorKey} key={errorKey} errors={errors} />
          ))}
      </div>
      <ButtonsContainer>{FormSectionToComponent[formMode]}</ButtonsContainer>
    </Header>
  );
};
export default SetItemsSelectionFormHeader;
