import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { ModalProps } from './Modal.const';
import { Container, Content, Loading, MainTitle, ModalHeader } from './NewModal.style';
import { Loader } from '../loader';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';

const Modal = ({
  children,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  ...rest
}: ModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();



  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container className={className} data-automation-id="modal" {...rest}>
        {withLoader &&  (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )} 
            <ModalHeader className='ModalHeader'>
              <MainTitle>
                {title && <NewTextModalTitle text={title} />}
                {isLocked && <LockIcon />}
              </MainTitle>
              {subtitle ?? null}
            </ModalHeader>
            <Content data-automation-id="modal-content">{children}</Content>
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default Modal;
