import React, { useMemo } from 'react';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { OfferTemplates } from 'utils/types/offers';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { NoImageText } from 'pages/offers/Offers.style';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { getOfferStatus } from 'utils/offer';
import { Tags } from 'components/shared/tags/Tags';
import { archiveOffer, onOfferArchive } from '../offerActions/OfferActions';
import {
  StyledButtonText,
  StyledIcon,
  StyledStatusLabel,
  StyledView,
  StyledArchiveIcon,
  OfferNameWrapper,
  StyledAddIcon,
  StyledRemoveIcon,
  OffersGridItemBottom,
  OffersGridItemBottomHeader,
  OfferSubTitleWrapper,
  OfferIDWrapper,
  OffersGridItemBottomFooter,
  OffersViewDetailContainer,
  OffersStatusContainer,
  StyledViewDetailText,
  StyledSeprator,
  NewOffersGridItemBackdrop,
  NewOffersGridItemTop,
  NewOffersGridItem,
  NewOffersGridItemActions,
  NewOffersGridItemImageContainer,
  NewOffersGridItemImage,
  NewOffersGridItemTemplate,
  NewOffersGridItemBottom,
} from './OfferItem.style';
import { OfferItemProps } from './OfferItem.consts';
import { getOfferById } from 'utils/api/offers';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';
import { handleKeyPress } from 'utils/keyPress';
import { NewTags } from 'components/shared/tags/NewTags';


const OfferItem = ({
  offer,
  isSelectMode = false,
  isSelected,
  onAddClick,
  onRemoveClick,
  onClick,
  onViewClick,
  className,
  setIsOfferArchieving,
}: OfferItemProps) => {
  const { config } = useSelector(marketConfig);
  const id = useMemo(() => Math.random().toString(8), [offer.updatedAt]);
  const primaryTranslation = offer?.versions[0]?.translationsMap[config.primaryLanguage];

  const onViewOfferClicked = async () => {
    const { data } = await getOfferById(offer.id);
    const offerData = data.getOffer;
    store.dispatch(openModal({ modal: Modals.OfferModal, props: { offer: offerData }, data: { mode: FormMode.View } }));
  };

  const onEditOfferClicked = async () => {
    const { data } = await getOfferById(offer.id);
    const offerData = data.getOffer;
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { offer: offerData, mode: FormMode.Edit },
        data: { mode: FormMode.Edit },
      }),
    );
  };

  const onOfferClick = () => {
    // eslint-disable-next-line no-unused-expressions
    onClick ? onClick() : onViewOfferClicked();
  };

  return (
    <NewOffersGridItem className={className} data-automation-id="offer-item" >
      <NewOffersGridItemTop onClick={onOfferClick} role="button" tabIndex={0} >
        <NewOffersGridItemBackdrop>
          <NewOffersGridItemActions>
            
            {isSelectMode ? (
              <>
                <StyledButtonText onClick={!onViewClick ? onViewOfferClicked : onViewClick} data-title="View" >
                  <StyledView name="view" />
                </StyledButtonText>
                {isSelected ? (
                  <StyledButtonText onClick={onRemoveClick} data-title="Remove">
                    <StyledRemoveIcon name="minusVector" />
                  </StyledButtonText>
                ) : (
                  <StyledButtonText onClick={onAddClick} data-title="Add">
                    <StyledAddIcon name="plusVector" />
                  </StyledButtonText>
                )}
              </>
            ) : (
              <>
                {!offer?.isLocked && (
                  <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                    {!offer.isArchive && (
                      <StyledButtonText 
                        aria-label="Button Offer Edit"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onEditOfferClicked();
                        }}
                        data-title="Edit"
                      >
                        <StyledIcon name="offerEdit" />
                      </StyledButtonText>
                    )}
                    <StyledButtonText
                        aria-label={`Button ${offer.isArchive ? 'Unarchive' : 'Archive'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return offer.isArchive ? archiveOffer(offer, false) : onOfferArchive(offer,setIsOfferArchieving);
                      }}
                      data-title={offer.isArchive ? 'Unarchive' : 'Archive'}
                    >
                        <StyledArchiveIcon name={offer.isArchive ? 'newUnarchive' : 'newArchive'} />
                    </StyledButtonText>
                  </RoleGuard>
                )}
              </>
            )}
          </NewOffersGridItemActions>
          <NewTags id={offer.id} tags={offer.tags} limit={4} />
        </NewOffersGridItemBackdrop>
        
        <NewOffersGridItemImageContainer>
          {primaryTranslation?.image ? (
            <NewOffersGridItemImage
              src={`${primaryTranslation?.image?.file}?${id}`}
              alt={`${primaryTranslation?.image?.name}`}
              loading="lazy"
              crossOrigin="anonymous"
            />
          ) : (
            <NoImageText>No Image Defined</NoImageText>
          )}
        </NewOffersGridItemImageContainer>
      </NewOffersGridItemTop>
      <StyledSeprator></StyledSeprator>
      <NewOffersGridItemBottom>
        <OffersGridItemBottomHeader>
          <OfferNameWrapper>
            <span tabIndex={0} title={primaryTranslation?.title}>{primaryTranslation?.title}</span>
            {offer.isLocked && <NewLockIcon dataFor={"offer-lock-icon"} />}
          </OfferNameWrapper>
            <OfferIDWrapper>
            <span tabIndex={0}>{`ID ${offer?.id}`}</span>
             </OfferIDWrapper>
          <NewOffersGridItemTemplate tabIndex={0}>
            {OfferTemplates[offer.templateType as keyof typeof OfferTemplates]?.name}
          </NewOffersGridItemTemplate>
          <OfferSubTitleWrapper>
            <span tabIndex={0}  title={primaryTranslation?.subtitle}>{primaryTranslation?.subtitle}</span>
          </OfferSubTitleWrapper>
        </OffersGridItemBottomHeader>
        <OffersGridItemBottomFooter>
          <OffersStatusContainer tabIndex={0}>
            <StyledStatusLabel type={getOfferStatus(offer)}/>
            </OffersStatusContainer>
          <OffersViewDetailContainer tabIndex={-1} onKeyDown={(event) => handleKeyPress(event, () => onOfferClick())}  >
            <StyledViewDetailText onClick={!onViewClick ? onViewOfferClicked : onViewClick} >
              View Details
            </StyledViewDetailText>
          </OffersViewDetailContainer>
        </OffersGridItemBottomFooter>
      </NewOffersGridItemBottom>
    </NewOffersGridItem>
  );
};

export default OfferItem;
