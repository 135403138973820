import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import { ToastContainer } from 'react-toastify';
import { ToastProps } from './ToastContainerWrapper.const';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer)<ToastProps & { modalOpen: boolean }>`
  z-index: ${zIndex.toast};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: ${({ modalOpen }) => (modalOpen ? '602px' : '1316px')};
  height: 48px;
  top: ${({ modalOpen }) => (modalOpen ? '78px' : '137px')};;
  left: 50%;
  .Toastify__toast {
    min-width: ${({ modalOpen }) => (modalOpen ? '602px' : '1316px')};;
    min-height: 48px;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;

    .Toastify__toast-body {
      padding: 0;
    }

    .Toastify__close-button {
      display: none;
    }

    &-body {
      font-size: 14px;
      color: ${({ appTheme }) => appTheme?.colors?.text?.primary};
    }
  }
`;
