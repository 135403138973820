import { FormMode, GenericItem, GenericSet, ProductSetType, SetOfEntity } from 'utils/types';
import type { AvailableAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/AvailableSetItemsListPanel.consts';
import type { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { GenericSetTitleFormatterProps } from 'components/shared/set/SetListItemHeader/SetListItemHeader.consts';

export interface SetItemsSelectionFormState {
  id?: number;
  name: string;
  description: string;
  selectedItemsById: Record<number | string, GenericItem>;
  selectedItemSetsById: Record<number, any>;
  excludedItemsById: Record<number | string, GenericItem>;
  excludedItemSetsById: Record<number, any>;
}

export interface SetItemsSelectionFormProps {
  itemSet: GenericSet;
  setOf: SetOfEntity;
  formMode: FormMode;
  searchPlaceholder?: string;
  onSave?: (state: SetItemsSelectionFormState) => void;
  onEdit?: () => void;
  onViewUsage?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  itemFormatter: (item: any) => string | JSX.Element;
  itemSetTitleFormatter?: (props: GenericSetTitleFormatterProps) => JSX.Element | string;
  availableAccordionsProps?: AvailableAccordionProps[];
  selectedAccordionsProps: SelectedAccordionProps[];
  fetchRootSetsByIds?: (sets: GenericSet[]) => Promise<GenericSet[]>;
  fetchTotalAvailableItems?: (searchValue: string, selectedItemsIds: number[]) => Promise<number>;
  forcedExcludedSets?: GenericSet[];
  forcedExcludedItems?: GenericItem[];
  supportSetFringes?: boolean;
  supportSingleItem?: boolean;
  isOptional?: boolean;
  setType?: ProductSetType;
  isNonProduct?: boolean;
  offerPreview?: boolean;
}

export enum ListType {
  Available = 'available',
  Selected = 'selected',
}

export enum AccordionContentType {
  Set = 'set',
  SetWithIndividualItems = 'setWithIndividualItems',
  Items = 'item',
}
