import React from 'react';
import { GridCellParams, GridColumnHeaderParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { TableProps } from 'components/shared/table/Table.consts';
import { formatDate } from 'utils/date';
import { ApprovalStatus, CampaignApprovalStatusLabel, DateTimeConfig, PriorityLevelLabels, PriorityLevels } from 'utils/types';
import { CampaignAlert, CampaignAlertLabel, CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import ActionsCell from 'components/shared/actionsCell/ActionsCell';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { isInArray } from 'utils/array';
import Tooltip from 'components/shared/tooltip/Tooltip';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';
import { NewButtonText } from 'components/shared/button';
import {
  AlertIconContainer,
  CellValueWrapper,
  NewStyledStatusLabel,
  StyledAlertIcon,
  StyledLabelIcon,
  TitleWrapper,
} from 'pages/campaigns/campaignManagement/Campaigns.style';
import { AvailableStatuses } from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection.consts';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';
import NewCheckbox from 'components/shared/checkbox/NewCheckbox';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';

export const getCampaignsTableProps = (
  onActionClick: (actionType: ActionType, campaign: CampaignProps) => void,
  dateTimeConfig: DateTimeConfig,
  drillDownToRedemptionsReport: (externalId: number) => void,
  selectionMode: {
    getIsChecked: (campaignId: number) => boolean;
    getSelectAllState: () => { checked: boolean; intermediate: boolean };
    onCampaignSelection: (campaignId: number) => void;
    onSelectAll: () => void;
  },
  isPriorityList: boolean,
): TableProps => {
  return {
    columns: [{
      field: 'selected',
      headerName: 'Select',
      width: 70,
      hide: !selectionMode,
      sortable: false,
      renderHeader: () => {
        const { checked, intermediate } = selectionMode.getSelectAllState();
        return (
          <NewCheckbox
            checked={checked}
            intermediate={intermediate}
            onClick={(e) => {
              selectionMode.onSelectAll();
              e.stopPropagation();
            }}
            id="select_all"
          />
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <NewCheckbox
            checked={selectionMode.getIsChecked(params.row.id)}
            onClick={(e) => {
              selectionMode.onCampaignSelection(params.row.id);
              e.stopPropagation();
            }}
            id="select_campaign"
          />
        );
      },
    },
    {
      field: 'externalId',
      headerName: 'ID',
      width: 100,
      sortable: true,
      renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <CellValueWrapper tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
            {row.externalId}
          </CellValueWrapper>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Name',
      width: 340,
      sortable: true,
      renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
          const { row } = params;
          return (
            <TitleWrapper tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
              <CellTitleWrapper title={row.title as string}/>
              {row.isLocked && <NewLockIcon dataFor={'campaign-lock-icon'} />}
            </TitleWrapper>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 150,
        sortable: true,
        type: 'singleSelect',
        valueOptions: Object.values(CampaignType),
        valueFormatter: (params: GridValueFormatterParams) => CampaignTypeLabel[params.value as CampaignType],
        renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const formattedValue = CampaignTypeLabel[row.type as CampaignType];
        return (
          <CellValueWrapper tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
            {formattedValue}
          </CellValueWrapper>
        );
      },
    },
      {
        field: 'schedule.campaignStart',
        headerName: 'Start',
        width: 180,
        sortable: true,
        type: 'datetime',
        valueGetter: (params: GridValueGetterParams) => params.row?.schedule?.campaignStart,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDate(params.value, dateTimeConfig, dateTimeConfig.startTimezone),
        renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const value = params.row?.schedule?.campaignStart;
        const formattedValue = formatDate(value, dateTimeConfig, dateTimeConfig.startTimezone);
        return (
          <CellValueWrapper tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
            {formattedValue}
          </CellValueWrapper>
        );
      },
    },
      {
        field: 'schedule.campaignEnd',
        headerName: 'End',
        width: 180,
        sortable: true,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => params.row?.schedule?.campaignEnd,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDate(params.value, dateTimeConfig, dateTimeConfig.endTimezone),
        renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const value = params.row?.schedule?.campaignEnd;
        const formattedValue = formatDate(value, dateTimeConfig, dateTimeConfig.endTimezone);
        return (
          <CellValueWrapper tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
            {formattedValue}
          </CellValueWrapper>
        );
      },
    },
      {
        field: 'status',
        headerName: 'Status',
        width: 180,
        sortable: true,
        align: 'left',
      renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
          const { value, row }: any = params;
          const id = Math.random().toString(32);
          return (
            <NewStyledStatusLabel tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onActionClick(ActionType.View, row);
            }
          }}>
            <NewStatusLabel
                type={value === ApprovalStatus.AssociationStopped ? ApprovalStatus.Active : (value as ApprovalStatus)}
              />
              {value === ApprovalStatus.AssociationStopped && (
                <StyledLabelIcon
                  name="associationStopped"
                  title={CampaignApprovalStatusLabel[ApprovalStatus.AssociationStopped]}
                />
              )}
            {row.alert && value !== ApprovalStatus.DeploymentFailed && (
              <>
                <AlertIconContainer tabIndex={0} data-for={`${id}-tooltip`} data-tip>
                  <StyledAlertIcon name="error" data-tip />
                </AlertIconContainer>
                <Tooltip
                  id={`${id}-tooltip`}
                  onHover
                  content={CampaignAlertLabel[row.alert as CampaignAlert]}
                  place="bottom"
                />
              </>
            )}
          </NewStyledStatusLabel>
        );
      },
    },
    {
      field: 'redemptions',
      headerName: 'Redemptions',
      width: 180,
      sortable: true,
      renderHeader: (params) => {
        return (
          <CellValueWrapper tabIndex={0}>
            {params.colDef.headerName}
          </CellValueWrapper>
        );
      },
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {isInArray([...AvailableStatuses, ApprovalStatus.AssociationStopped], row.status) ? (
              <NewButtonText
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  drillDownToRedemptionsReport(row.externalId);
                }}
              >
                {row.redemptions}
              </NewButtonText>
            ) : null}
          </>
        );
      },
    },
    ...(isPriorityList
      ? [
          {
            field: 'priority',
            headerName: 'Priority',
            width: 110,
            sortable: true,
            valueGetter: (params: GridValueGetterParams) => params.row?.priority,
            renderHeader: (params: GridColumnHeaderParams) => {
              return (
                <CellValueWrapper tabIndex={0}>
                  {params.colDef.headerName}
                </CellValueWrapper>
              );
            },
            renderCell: (params: GridCellParams) => {
              const priority = params.value as PriorityLevels;
              const label = PriorityLevelLabels[priority];
              const { row }: any = params;
              return <span tabIndex={0} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onActionClick(ActionType.View, row);
                }
              }}>{label}</span>;
            },
          },
        ]
      : []),
    {
        field: 'actions',
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => {
          const { id, row } = params;
          return (
            <>
              {!row.isLocked && !row?.inProgress && (
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  <div id={`Actions-${id}`} style={{
                  opacity: 0, // Makes the div invisible
                  pointerEvents: 'none', // Prevents any interaction with the div
                  position: 'absolute', // Removes it from the normal document flow
                }}>
                    <ActionsCell
                      actionTypes={
                        isInArray(
                          [
                            ApprovalStatus.Archived,
                            ApprovalStatus.Revoked,
                            ApprovalStatus.DeploymentFailed,
                            ApprovalStatus.Expired,
                          ],
                          row.status,
                        )
                          ? [ActionType.View]
                          : [ActionType.View, ActionType.Edit]
                      }
                      onActionClick={(actionType: ActionType) => onActionClick(actionType, row as CampaignProps)}
                    rowId={`Actions-${id}`}
                    />
                  </div>
                </RoleGuard>
              )}
              <div id={`Actions-${id}`} style={{
                  opacity: 0, // Makes the div invisible
                  pointerEvents: 'none', // Prevents any interaction with the div
                  position: 'absolute', // Removes it from the normal document flow
                }}>
                <ActionsCell
                  actionTypes={[ActionType.View]}
                  onActionClick={(actionType: ActionType) => onActionClick(actionType, row as CampaignProps)}
                rowId={`Actions-${id}`}
                />
              </div>
            </>
          );
        },
      },
    ],
    rows: [] as any[],
    sortingMode: 'server',
    sortingOrder: ['desc', 'asc'],
  };
};
