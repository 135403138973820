import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextArea from 'components/shared/textArea/TextArea';
import TextField from 'components/shared/textField/TextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import ControlledCheckbox from 'components/shared/controlledCheckbox/ControlledCheckbox';
import Checkbox from 'components/shared/checkbox/Checkbox';
import NewRadioGroup from 'components/shared/radioGroup/NewRadioGroup';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:530px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction:column;
  margin-bottom: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const GamingFormRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom:16px;
`;

export const NewFormRow = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
  margin-top:16px;
  align-items: baseline;
`;

export const CampaignDescription = styled(TextArea)`
  width: 100%;
  display: flex;
  font-weight:700;

`;

export const CampaignName = styled(TextField)<{ isGeneratedName: boolean }>`
  width: 257px;
  margin-left:16px;
  opacity: ${({ isGeneratedName }) => (isGeneratedName ? 0.5 : 1)};
`;

export const GamingCampaignName = styled(TextField)<{ isGeneratedName: boolean }>`
  width: 257px;
  margin-right:16px;
  opacity: ${({ isGeneratedName }) => (isGeneratedName ? 0.5 : 1)};
`;


export const VoucherDistribution = styled(NewRadioGroup)`
  width: 295px;
  margin-left:16px;
`;

export const StyledCheckbox = styled(ControlledCheckbox)<{ nameHasError: boolean }>`
  margin-top: ${({ nameHasError }) => (nameHasError ? `${typography.unit * 6}px` : `${typography.unit}px`)};
`;

export const StyledPriorityCheckbox = styled(Checkbox)`
  margin-top: ${typography.unit * 4}px;
  padding-left: 1rem;
  align-self: flex-end;
font-size: 12px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;


`;
