import { ApprovalStatus, BaseEntity } from 'utils/types';
import { Language } from 'utils/languages';
import { Image } from './images';
import { Term } from './terms';
import { Tag } from './tags';

export interface BaseApproval {
  id?: number;
  approvalStatus: ApprovalStatus;
  comment?: string;
  approver?: number;
}

export interface BaseOfferTranslation extends BaseEntity {
  id: number;
  title: string;
  subtitle: string;
  posTitle: string;
  description: string;
  language: Language;
  image: Image;
}

export interface BaseOfferApproval extends BaseApproval {
  baseOfferVersion?: number;
}

export interface BaseOfferVersion extends BaseEntity {
  id?: number;
  baseOfferId?: number;
  translations: BaseOfferTranslation[];
  translationsMap: { [lang: string]: BaseOfferTranslation };
  approvals: BaseOfferApproval[];
  status: ApprovalStatus;
  term?: Term;
  templateValues: { [key: string]: any };
  pointOfDistribution: PointOfDistribution[];
  isDraft?: boolean;
}

export interface BaseOffer extends BaseEntity {
  id: number;
  name: string;
  versions: BaseOfferVersion[];
  tags: Tag[];
  templateType: keyof typeof OfferTemplates;
  isArchive: boolean;
  isLocked: boolean;
  segmentId: string;
  inProgress?: string;
}

export const OfferApprovalStatuses = [
  ApprovalStatus.Draft,
  ApprovalStatus.PendingApproval,
  ApprovalStatus.Approved,
  ApprovalStatus.Rejected,
  ApprovalStatus.Archived,
];

export enum OfferActions {
  Archive = 'archive',
  Unarchive = 'unarchive',
  Duplicate = 'duplicate',
  Preview = 'preview',
  ViewImpact = 'view offer usage',
  Edit = 'edit',
  Reject = 'reject'
}

export const OfferActionsList: { id: OfferActions; name: OfferActions }[] = [
  { id: OfferActions.Duplicate, name: OfferActions.Duplicate },
  { id: OfferActions.Edit, name: OfferActions.Edit },
  { id: OfferActions.Archive, name: OfferActions.Archive },
  { id: OfferActions.ViewImpact, name: OfferActions.ViewImpact },

];

export const DraftOfferActionsList: { id: OfferActions; name: OfferActions }[] = [
  { id: OfferActions.Duplicate, name: OfferActions.Duplicate },
  { id: OfferActions.Reject, name: OfferActions.Reject },
  { id: OfferActions.Archive, name: OfferActions.Archive },
  { id: OfferActions.ViewImpact, name: OfferActions.ViewImpact },

];



export const OfferTemplates: {
  [key: string]: {
    id: string;
    name: string;
  };
} = {
  '1': {
    id: '1',
    name: 'BOGO',
  },
  '2': {
    id: '2',
    name: 'Single Item Discount',
  },
  '3': {
    id: '3',
    name: 'Multi Item Discount',
  },
  '4': {
    id: '4',
    name: 'Substitution',
  },
  '5': {
    id: '5',
    name: 'Order Discount',
  },
  /* OMS-520 - Hide "Punch Card" references from UI
    '6': {
      id: '6',
      name: 'Punch Card',
    },
     */
  '7': {
    id: '7',
    name: 'Buy, Buy, Get',
  },
  '8': {
    id: '8',
    name: 'Buy, Get, Get',
  },
  '10': {
    id: '10',
    name: 'Price Deal',
  },
  '11': {
    id: '11',
    name: 'Combo Price Deal',
  },
  '13': {
    id: '13',
    name: '2/3/4 Product Combo',
  },
};

export enum DoubleDippingRule {
  NeitherDiscountedItemsNorEVMsIncluded = 'Neither discounted items nor EVMs included',
  DiscountedItemsIncludedEVMsExcluded = 'Discounted items included, EVMs excluded',
  EVMsExcludedAndDiscountedItemsAreAtBasePrice = 'EVMs excluded and discounted items are at base price',
  EVMsIncludedDiscountedItemsExcluded = 'EVMs included, discounted items excluded',
  BothDiscountedItemsAndEVMsIncluded = 'Both discounted items and EVMs included',
  EVMsIncludedAndDiscountedItemsAreAtBasePrice = 'EVMs included and discounted items are at base price',
}

export const DoubleDippingRuleRecord: Record<DoubleDippingRule, string> = {
  [DoubleDippingRule.NeitherDiscountedItemsNorEVMsIncluded]: 'Neither discounted items nor EVMs included',
  [DoubleDippingRule.DiscountedItemsIncludedEVMsExcluded]: 'Discounted items included, EVMs excluded',
  [DoubleDippingRule.EVMsExcludedAndDiscountedItemsAreAtBasePrice]:
    'EVMs excluded and discounted items are at base price',
  [DoubleDippingRule.EVMsIncludedDiscountedItemsExcluded]: 'EVMs included, discounted items excluded',
  [DoubleDippingRule.BothDiscountedItemsAndEVMsIncluded]: 'Both discounted items and EVMs included',
  [DoubleDippingRule.EVMsIncludedAndDiscountedItemsAreAtBasePrice]:
    'EVMs included and discounted items are at base price',
};

export enum PointOfDistribution {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export const PointOfDistributionRecord: Record<PointOfDistribution, string> = {
  [PointOfDistribution.Delivery]: 'Delivery',
  [PointOfDistribution.Pickup]: 'Pickup',
};

export enum DiscountType {
  MoneyOff = 'moneyOff',
  Percent = 'percent',
  Free = 'free',
  FixedPrice = 'fixedPrice',
}

export enum DiscountCondition {
  None = 'none',
  WithPurchaseOf = 'withPurchaseOf',
  MinimumPurchase = 'minimumPurchase',
  NoPurchaseRequired = 'noPurchaseRequired',
  WithAnyPurchase = 'withAnyPurchase',
}

export const DiscountConditionRecord: Record<DiscountCondition, string> = {
  [DiscountCondition.None]: '',
  [DiscountCondition.WithPurchaseOf]: 'Purchase of',
  [DiscountCondition.MinimumPurchase]: 'Minimum Purchase',
  [DiscountCondition.NoPurchaseRequired]: 'No Purchase',
  [DiscountCondition.WithAnyPurchase]: 'Any Purchase',
};
