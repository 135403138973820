import styled from '@emotion/styled';
import { appTheme } from 'styles/themes/defaultTheme';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';

export const RadioItemWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const Label = styled.span<{ disabled: boolean; theme?: appTheme }>`
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  align-items: center;
  white-space: nowrap;
`;

export const OuterCircle = styled.div<{ isSelected: boolean; disabled: boolean; theme?: appTheme }>`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ isSelected, disabled, theme }) =>
      isSelected
        ? disabled
          ? newGlobalTheme.colors.global.borderColorboxes
          : newGlobalTheme.colors.button.border.secondary
        : newGlobalTheme.colors.global.borderColorboxes};

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${typography.transition}s ease;
  margin-right: ${typography.unit * 2}px;
`;

export const InnerCircle = styled.div<{ disabled: boolean; theme?: appTheme }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.checkbox.disabledCheckedBg : newGlobalTheme.colors.global.brandMain};
  transition: all ${typography.transition}s ease;
`;
