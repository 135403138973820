import styled from '@emotion/styled';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import typography from 'styles/typography';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { newTypography } from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const OfferSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;



export const StyledSearchTextField = styled(SearchTextField)`
  margin-right: ${typography.unit * 6}px;
  width: 150px;
`;

export const StyledSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 6}px;
` as unknown as typeof Selectbox;

export const NewStyledFilterBarSelectForOfferSelection = styled(NewSelectbox)`
  margin-right:20px;
.css-4s3x4e-control{
  background-color:${newGlobalTheme.colors.global.background}
  }
` as unknown as typeof Selectbox;
