import React, { useEffect, useRef } from 'react';
import { Container, StyledButtonClose, StyledIcon, StyledMsg } from './Toast.style';
import { ToastProps } from './Toast.const';
import { IconNameByType } from '../notifications';
import { useSelector } from 'react-redux';
import { modal as modalSlice } from 'app/slices/modals';

const Toast = ({ type, children, closeToast, className, staticImport = false }: ToastProps) => {
  const {modal} = useSelector(modalSlice);

  const toastRef = useRef(null);

  useEffect(() => {
    if (type === 'error' && toastRef.current) {
      toastRef.current.focus();
    }
  }, [type]);
  return (
    <Container modalOpen={modal ? true : false } className={className} type={type} data-automation-id="toast" ref={toastRef} tabIndex={-1}>
      <StyledIcon name={IconNameByType[type]} type={type}  staticImport={staticImport} />
      <StyledMsg modalOpen={modal ? true : false }>{children}</StyledMsg>
      {closeToast && <StyledButtonClose onClick={closeToast} />}
    </Container>
  );
};

export default Toast;
