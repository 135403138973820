import React from 'react';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ApprovalStatus } from 'utils/types';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { StyledLabelContainer, StyledInfo } from '../../OfferForm.style';
import { OfferStatusLabelProps } from './OfferStatusLabel.const';
import { getOfferStatus } from '../../../../../../utils/offer';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';

export const OfferStatusLabel = ({ offer }: OfferStatusLabelProps) => (
  <StyledLabelContainer>
    <NewStatusLabel type={getOfferStatus(offer)} />
    {offer?.versions[0]?.status === ApprovalStatus.Rejected && offer?.versions[0]?.approvals[0]?.comment && (
      <div data-for="info-tooltip" {...{ 'data-tip': true }}>
        <StyledInfo name="info" onClick={() => null} />
      </div>
    )}
    <Tooltip
      id="info-tooltip"
      place="bottom"
      content={offer?.versions[0]?.approvals[0]?.comment}
      onClose={() => {
        hideTooltip('#info-tooltip');
        ReactTooltip.hide();
      }}
    />
  </StyledLabelContainer>
);
