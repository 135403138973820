import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import OfferVersionItem from '../offerVersionItem/OfferVersionItem';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width:530px;
  flex-direction:column;
  padding-left:4px;
`;

export const SelectOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding:0px;
  margin-bottom:8px;
`;

export const StyledButtonText = styled(ButtonText)`
font-size: 14px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
color:${newGlobalTheme.colors.global.textSubheading}


`;

export const StyledOfferVersionItem = styled(OfferVersionItem)`
width:530px;
height:188px;
`;
