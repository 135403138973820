import styled from '@emotion/styled';
import typography from 'styles/typography';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';

export const CalendarWrapper = styled.div<StyledWithThemeProps>`
  flex-grow: 1;

  // Event global styles
  .rbc-event {
    margin-bottom: 4px;
    background-color: ${({ theme }) => theme.colors.events.national};
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 13px;
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 0;

    &.local-event {
      background-color: ${({ theme }) => theme.colors.events.local};
    }

    &.rbc-event-continues-prior,
    &.rbc-event-continues-after {
      position: relative;

      &.local-event {
        &::before,
        &::after {
          background-color: ${({ theme }) => theme.colors.events.local};
          border-color: ${({ theme }) => theme.colors.events.local};
        }
      }

      &::before,
      &::after {
        display: inline;
        background-color: ${({ theme }) => theme.colors.events.national};
        border: ${({ theme }) => `1px solid ${theme.colors.events.national}`};
      }
    }

    &.rbc-event-continues-prior {
      &::before {
        content: '';
        transform: rotate(45deg);
      }
      &:not(.rbc-event-continues-after) {
        width: calc(99% - 10px);
      }
    }

    &.rbc-event-continues-after {
      &::after {
        content: '';
        transform: rotate(-45deg);
      }
    }
  }

  .rbc-month-header {
    .rbc-header {
      border-bottom: 0;

      span {
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text.disabled};
      }
    }
  }

  .period {
    background-color: #dde7fe !important;
  }

  // Month view styles
  .rbc-month-view {
    border: none;

    .rbc-date-cell {
      height: 24px;
      padding: 2px 0;

      button {
        height: 100%;
        font-family: ${typography.primaryFont};
        font-weight: 500;
        font-size: 12px;
      }
    }

    .rbc-now {
      button {
        background: #5075f7;
        color: white;
        border-radius: 50%;
        min-width: 22px;
        height: 22px;

        &.big-now {
          width: 30px;
        }
      }
    }

    .rbc-event-continues-prior,
    .rbc-event-continues-after {
      &::before,
      &::after {
        height: 15px;
        width: 15px;
        position: absolute;
        z-index: -1;
        top: 3px;
        border-radius: 2px 0 3px 5px;
      }
    }

    .rbc-event-continues-prior {
      margin-left: 10px;
      &::before {
        left: -7px;
      }
    }

    .rbc-event-continues-after {
      margin-right: 10px;
      width: auto;

      &::after {
        right: -7px;
      }
    }
  }

  .rbc-date-cell {
    text-align: center;
  }

  .rbc-today {
    background-color: initial;
  }

  .rbc-time-content {
    display: none;
  }

  // Weekly view styles
  .rbc-time-view {
    border: 0;

    @media only print {
      overflow: visible;
    }

    .rbc-time-header-content {
      border: 0;

      .rbc-time-header-cell {
        min-height: 60px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
      }
    }

    .rbc-time-header {
      flex: 1;
      overflow: scroll;

      @media only print {
        overflow: visible;
      }
    }
    .rbc-label {
      display: none;
    }

    .rbc-allday-cell {
      @media only screen {
        height: auto;
        flex-grow: 1;
      }

      @media only print {
        height: 100%;
        overflow: visible;
        .rbc-row-content {
          padding: 0 0 140px 0;
        }
      }
    }

    .rbc-header {
      border-bottom: 0;
      min-height: 60px;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .rbc-button-link {
        padding: ${typography.unit}px 0;
        font-family: ${typography.primaryFont};
        font-weight: 500;
        font-size: 13px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text.disabled};
        display: block;
      }

      span {
        display: block;
        color: ${({ theme }) => theme.colors.text.primary};
        text-transform: none;
      }

      &.rbc-today {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #5075f7;
          color: white;
          border-radius: 50%;
          width: 22px;
          height: 22px;
        }

        &:has(.big-now) button span {
          width: auto;
        }
      }
    }

    .rbc-event {
      height: 68px;
      margin-bottom: ${typography.unit * 2}px;
      padding-top: 3px;

      &.rbc-event-continues-after::after,
      &.rbc-event-continues-prior::before {
        height: 49px;
        width: 49px;
        position: absolute;
        z-index: -1;
        top: 10px;
        border-radius: 2px 0 3px 5px;
      }

      &.rbc-event-continues-after {
        margin-right: 50px;
        width: auto;

        &::after {
          right: -25px;
        }
      }

      &.rbc-event-continues-prior {
        margin-left: 50px;

        &::before {
          left: -25px;
        }
        &:not(.rbc-event-continues-after) {
          width: calc(99% - 50px);
        }
      }
    }
  }

  .rbc-off-range-bg {
    background: initial;
  }

  .rbc-row-content {
    z-index: 0;

    .rbc-event-content {
      display: flex;
      align-items: center;
    }
  }
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  background: #fff;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CampaignLoaderWrapper = styled.div`
  height: 60%;
  width: 90%;
  position: absolute;
  z-index: 10;
  background: #fff;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowMoreTooltip = styled.div<
  StyledWithThemeProps & { top: number; left: number; width: number; height: number }
>`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  background-color: white;
  border: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 8px 25px rgba(0, 0, 0, 0.22);
  padding: 0 ${typography.unit * 4}px;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${typography.unit}px 0;

    h4 {
      text-transform: uppercase;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.text.disabled};
    }

    h5 {
      font-size: 20px;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    overflow: scroll;
  }
`;

export const ShowMoreEventItem = styled.li<StyledWithThemeProps & { isLocal: boolean }>`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    background-color: ${({ isLocal, theme }) => (isLocal ? theme.colors.events.local : theme.colors.events.national)};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }
`;

export const StyledCloseButton = styled(ButtonText)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${({ theme }) => theme.colors.text.disabled};
`;

export const RecurrenceIcon = styled(Icon)`
  margin-right: ${typography.unit}px;
`;

export const BellIcon = styled(Icon)`
  margin-right: ${typography.unit}px;
`;

export const TriggerEventIcon = styled(RecurrenceIcon)``;
