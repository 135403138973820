import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { TagsProps } from './Tags.consts';
import { NewStyledButtonText, NewStyledChip, NewStyledMore, NewStyledTags, NewStyledTooltip, NewTagsList } from './NewTags.style';

export const NewTags = ({ id, tags, limit = 7 }: TagsProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <NewStyledTags data-automation-id="tags">
      <NewTagsList>
        {tags?.slice(0, limit).map((tag, index) => (
          <NewStyledChip key={`tagChip_${index}_${id}`}>{tag.name}</NewStyledChip>
        ))}
      </NewTagsList>
      {tags.length > limit && (
        <>
          <NewStyledButtonText
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-for={`tags_tooltip_${id}`}
            data-tip
          >
            <NewStyledMore name="more" />
          </NewStyledButtonText>
          <NewStyledTooltip id={`tags_tooltip_${id}`} place="bottom">
            {tags?.slice(limit)?.map((tag, index) => (
              <NewStyledChip key={`tagChip_${index + limit}_${id}`}>{tag.name}</NewStyledChip>
            ))}
          </NewStyledTooltip>
        </>
      )}
    </NewStyledTags>
  );
};
