import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import typography from 'styles/typography';
import { ModalProps } from './Modal.const';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';


export const OfferContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${typography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  box-shadow: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);
  overflow-y: scroll; 
  height: 85vh;
  overflow-y: visible;
  min-width:  602px;
  padding: 36px 0px 36px 0px;


`;

export const OfferContent = styled.div<{contentWidth?: number}>`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  ${({contentWidth})=> contentWidth && `width: ${contentWidth}px;`}
`;


export const OfferModalHeader = styled.div`

    margin-bottom: 20px;
    font-family: Speedee;   
    color: ${newGlobalTheme.colors.global.primaryTextColor}; 
    font-weight: 700;
    line-height: 22.834px; /* 81.55% */
    letter-spacing: -0.84px;
    padding-left: 36px; 
    padding-right: 36px; 
    display: flex;
    flex-direction: column; 
    gap: 16px; 
    height: auto; 

`;

export const OfferMainTitle = styled.div`
  line-height: 31px; 
  display: flex;
  justify-content: space-between;
  height: 28px;
  :focus-visible {
      outline: none !important;
  }
`;
