import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { Icon } from 'components/shared/icon';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import  OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const TemplateContainer = styled.div<StyledWithThemeProps>`
  border-radius: 5px;
  background: ${newGlobalTheme.colors.global.background}; 
  box-shadow: 1px 1px 8.3px 1px rgba(0, 0, 0, 0.16);  
  padding: 16px;
  font-family: Speedee; 
  display: flex;
  flex-direction: column;

`;
export const StyledBuySelectbox = styled(NewSelectbox)`

`;

export const StyledForSelectbox = styled(NewSelectbox)`
  
`;

export const StyledLabel = styled.label<{disabled?: boolean}>`
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 22px;
  font-size: 12px; 
  font-weight: 700; 

 &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    border-radius: 5px; 
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
  ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}`: `color: ${newGlobalTheme.colors.text.primary}`}; 
`;

export const StyledLightLabel = styled(StyledLabel)<{disabled?: boolean, weight?: number}>`
  ${({weight}) => weight ? `font-weight: ${weight};`: `font-weight: 700;`}

  ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}`: `color: ${newGlobalTheme.colors.text.primary}`}; 
`;

export const StyledLabelUpto = styled(StyledLabel)`

`;

export const StyledSelectbox = styled(NewSelectbox)`
  width: auto;
  margin-right: ${typography.unit * 2}px;
  div {
    font-size: 12px; 
  }
`;

export const StyledTextField = styled(OfferTemplateTextField)`
`;

export const StyledCheckbox = styled(Checkbox)`
  div {
    div[data-automation-id="checkbox"] {
      ${({disabled}) => disabled && 'border: 1px solid #F7E1A4; '}
    }
  }
  svg {
    ${({disabled}) => disabled && 'background-color: #F7E1A4; '}
    width: 100%; 
    height: 100%;
  }
  label {
    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}`: `color: ${newGlobalTheme.colors.text.primary}`}; 
      font-weight: 700; 
  }
`;

export const ProductPicker = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: row; 
  font-size: 12px; 
  text-overflow: ellipsis;
  word-wrap: break-word;
 ${({disabled}) => disabled && `color: ${newGlobalTheme.colors.text.disabledSave};`}
  span {
    padding-right: 8px; 
   ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }
  #text {
   ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }


`;

export const ProductName = styled.span``;

export const StyledEditIcon = styled(Icon)`
  margin: 0 0 0 ${typography.unit * 2}px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 2}px;
`;

export const StyledViewIcon = styled(Icon)`
  width: 18px;
  fill: ${({ theme }) => `${theme.colors.global.inputPrimary};`};
`;


export const EnterValueWrapper = styled.div<{width?: number, disabled?: boolean, errors?: boolean}>`
    display: flex; 
    flex-direction: row; 
    align-content: center; 
    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
    ${({width}) => width && `width: ${width}px;`}
    ${({errors}) => errors ? `border-bottom: 1px solid ${newGlobalTheme.colors.text.error};` : `border-bottom: 1px solid ${newGlobalTheme.colors.text.disabledSave}; `}
    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};

`
