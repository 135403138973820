import styled from '@emotion/styled';

export const StyledTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  letter-spacing: -0.84px;
`;
