import React, { useEffect } from 'react';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/date';
import { ApprovalStatus, ApprovalStatusLabel } from 'utils/types';
import { CampaignAlert, CampaignAlertLabel, CampaignType } from 'utils/types/campaigns';
import Table from 'components/shared/table/NewTable';
import { getDashboardCampaignsTableProps } from 'pages/dashboard/DashboardCampaignTableProps';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { OfferFilters } from 'pages/offers/Offers.const';
import { dashboard as dashboardSlice, defaultDashboardData, loadDashboardData } from 'app/slices/dashboard';
import { store } from 'app/store';
import { marketConfig } from 'app/slices/config';
import { useHistory } from 'react-router-dom';
import {
  AlertsHeader,
  Board,
  CampaignsOverview,
  Column,
  InnerBoard,
  LastUpdated,
  AlertLabel,
  BigNumber,
  CampaignSection,
  SmallNumber,
  StatusBigBox,
  StatusSmallBox,
  NoRedemptionsSection,
  OffersOverview,
  OverviewHeader,
  OverviewSubHeader,
  PendingApprovalStartsInBox,
  RedemptionsHeader,
  SideOverviewContent,
  SideOverviewHeader,
  StatusRow,
  StyledHeader,
  WrapperBoard,
  StyledIcon,
  OffersOverviewSmallBox,
  NoRedemptionHeader,
  RedemptionsHeaderSpan,
  NoRedemptionAlertLabel,
  NoRedemptionAlertLabelSpan,
  NoRedemptionTableContainer,
  OverviewHeaderContainer,
  LabelText,
  NoRedemptionAlert,
} from './Dashboard.style';
import UpdatesOverview from 'pages/dashboard/components/updatesOverview/UpdatesOverview';
import { getEnableManagementByZone } from '../../components/header/components/notificationsMenu/notifications.utils';
import RedemptionOverview from './components/redemptionOverview/RedemptionOverview';
import { Icon } from 'components/shared/icon';
import { handleKeyPress } from 'utils/keyPress';

let embz: tabName;

const Dashboard = () => {
  if (getEnableManagementByZone() === false) {
    embz = tabName.Campaigns;
  } else {
    embz = tabName.CampaignManagement;
  }
  const history = useHistory();
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone, approvalRequiredNotificationThreshold } = config;
  const { data } = useSelector(dashboardSlice);
  const campaignRoute = embz;

  const routeToSpecificPath = (tab: tabName, filters = '') => {
    const url = `${tabToPathMap[tab]}?${filters}`;
    history.push(url);
  };

  const openCampaignsById = (ids: number[]) =>
    ids?.length && routeToSpecificPath(campaignRoute, `${CampaignFilterType.Id}=${ids.join(',')}`);

  const openCampaignStatus = (status: ApprovalStatus) =>
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.Status}=${status}`);

  const openCampaignAlerts = (alert: CampaignAlert) =>
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.Alert}=${alert}`);

  const openOfferStatus = (status: ApprovalStatus) =>
    routeToSpecificPath(tabName.Offers, `${OfferFilters.Status}=${status}`);

  const onCampaignNameClick = (id: number) => {
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.SearchQuery}=${id}`);
  };

  useEffect(() => {
    store.dispatch(loadDashboardData());
  }, []);

  return (
    <PageContainer>
      <Board>
        <WrapperBoard>
          <InnerBoard>
            <StyledHeader data-automation-id="header">
              <NewTextPageTitle text={tabName.Dashboard} />
            </StyledHeader>
            <CampaignsOverview>
              <OverviewHeaderContainer>
                <OverviewHeader>Campaigns Overview</OverviewHeader>
                {data.campaigns.lastUpdated && (
                  <LastUpdated align="right">
                    Last Updated: {formatDate(data.campaigns.lastUpdated, { dateFormat, timeFormat }, startTimezone)}
                  </LastUpdated>
                )}
              </OverviewHeaderContainer>
              <CampaignSection data-automation-id="campaigns-overview">
                <StatusRow>
                  <StatusBigBox
                    color="green"
                    tabIndex={0}
                    onClick={() => openCampaignStatus(ApprovalStatus.Active)}
                    onKeyDown={(event) => handleKeyPress(event, () => openCampaignStatus(ApprovalStatus.Active))}
                    data-automation-id="campaigns-overview-active"
                    aria-label={`${data?.campaigns[ApprovalStatus.Active]} active campaigns`}
                    role="link"
                  >
                    <BigNumber>{data.campaigns[ApprovalStatus.Active]}</BigNumber>
                    <AlertLabel color="black">
                      <StyledIcon name="polygonGreen" width={13} height={13} />
                      {ApprovalStatusLabel[ApprovalStatus.Active]}
                    </AlertLabel>
                  </StatusBigBox>
                  <StatusBigBox
                    color="red"
                    tabIndex={0}
                    onClick={() => openCampaignStatus(ApprovalStatus.Rejected)}
                    onKeyDown={(event) => handleKeyPress(event, () => openCampaignStatus(ApprovalStatus.Rejected))}
                    data-automation-id="campaigns-overview-rejected"
                    aria-label={`${data?.campaigns[ApprovalStatus.Rejected]} rejected campaigns`}
                    role="link"
                  >
                    <BigNumber>{data.campaigns[ApprovalStatus.Rejected]}</BigNumber>
                    <AlertLabel color="black">
                      <StyledIcon name="polygonRed" width={13} height={13} />
                      <span>{ApprovalStatusLabel[ApprovalStatus.Rejected]}</span>
                    </AlertLabel>
                  </StatusBigBox>
                  <StatusBigBox
                    color="red"
                    tabIndex={0}
                    onClick={() => openCampaignStatus(ApprovalStatus.DeploymentFailed)}
                    onKeyDown={(event) =>
                      handleKeyPress(event, () => openCampaignStatus(ApprovalStatus.DeploymentFailed))
                    }
                    data-automation-id="campaigns-overview-deployment-failed"
                    aria-label={`${data?.campaigns[ApprovalStatus.DeploymentFailed]} deployment failed campaigns`}
                    role="link"
                  >
                    <BigNumber>{data.campaigns[ApprovalStatus.DeploymentFailed]}</BigNumber>
                    <AlertLabel color="black">
                      <StyledIcon name="polygonRed" width={13} height={13} />
                      <span>{ApprovalStatusLabel[ApprovalStatus.DeploymentFailed]}</span>
                    </AlertLabel>
                  </StatusBigBox>
                  <StatusBigBox
                    color="orange"
                    tabIndex={0}
                    onClick={() => openCampaignStatus(ApprovalStatus.PendingApproval)}
                    onKeyDown={(event) =>
                      handleKeyPress(event, () => openCampaignStatus(ApprovalStatus.PendingApproval))
                    }
                    data-automation-id="campaigns-overview-pending-approval"
                    aria-label={`${data?.campaigns[ApprovalStatus.PendingApproval]} pending approval campaigns`}
                    role="link"
                  >
                    <SmallNumber>{data.campaigns[ApprovalStatus.PendingApproval]}</SmallNumber>
                    <LabelText>{ApprovalStatusLabel[ApprovalStatus.PendingApproval]}</LabelText>
                    <PendingApprovalStartsInBox
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        openCampaignsById(data.campaigns.pendingApprovalNotice);
                      }}
                      onKeyDown={(event) =>
                        {
                          event.stopPropagation();
                          handleKeyPress(event, (e: any) => {
                          e.stopPropagation();
                          openCampaignsById(data.campaigns.pendingApprovalNotice);
                        })}
                      }
                    >
                      <SmallNumber>{data.campaigns.pendingApprovalNotice.length}</SmallNumber>
                      <LabelText>Start in the next {approvalRequiredNotificationThreshold} hours</LabelText>
                    </PendingApprovalStartsInBox>
                  </StatusBigBox>
                </StatusRow>
              </CampaignSection>
              <CampaignSection data-automation-id="alerts">
                <AlertsHeader>
                  <OverviewSubHeader>Alerts</OverviewSubHeader>
                </AlertsHeader>
                <StatusRow>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.UpdateFailed)}
                    onKeyDown={(event) => handleKeyPress(event, () => openCampaignAlerts(CampaignAlert.UpdateFailed))}
                    color="grey"
                    tabIndex={0}
                    borderColor="red"
                    data-automation-id="alerts-update-failed"
                    aria-label={`${data?.alerts[CampaignAlert.UpdateFailed]} update failed campaigns`}
                    role="link"
                  >
                    <SmallNumber isAlert>{data.alerts[CampaignAlert.UpdateFailed]}</SmallNumber>
                    <AlertLabel>
                      <Icon name="dashboardAlert" width={23} height={23}></Icon>
                      {CampaignAlertLabel[CampaignAlert.UpdateFailed]}
                    </AlertLabel>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.RevokeFailed)}
                    onKeyDown={(event) => handleKeyPress(event, () => openCampaignAlerts(CampaignAlert.RevokeFailed))}
                    color="grey"
                    tabIndex={0}
                    borderColor="red"
                    data-automation-id="alerts-revoke-failed"
                    aria-label={`${data?.alerts[CampaignAlert.RevokeFailed]} revoke failed campaigns`}
                    role="link"
                  >
                    <SmallNumber isAlert>{data.alerts[CampaignAlert.RevokeFailed]}</SmallNumber>
                    <AlertLabel>
                      <Icon name="dashboardAlert" width={23} height={23}></Icon>
                      {CampaignAlertLabel[CampaignAlert.RevokeFailed]}
                    </AlertLabel>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.Conflicted)}
                    onKeyDown={(event) => handleKeyPress(event, () => openCampaignAlerts(CampaignAlert.Conflicted))}
                    color="grey"
                    tabIndex={0}
                    borderColor="red"
                    data-automation-id="alerts-conflicted"
                    aria-label={`${data?.alerts[CampaignAlert.Conflicted]} conflicted campaigns`}
                    role="link"
                  >
                    <SmallNumber isAlert>{data.alerts[CampaignAlert.Conflicted]}</SmallNumber>
                    <AlertLabel>
                      <Icon name="dashboardAlert" width={23} height={23}></Icon>
                      {CampaignAlertLabel[CampaignAlert.Conflicted]}
                    </AlertLabel>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.StopAssociationFailed)}
                    onKeyDown={(event) =>
                      handleKeyPress(event, () => openCampaignAlerts(CampaignAlert.StopAssociationFailed))
                    }
                    color="grey"
                    tabIndex={0}
                    borderColor="red"
                    data-automation-id="alerts-stop-association-failed"
                    aria-label={`${data?.alerts[CampaignAlert.StopAssociationFailed]} Stop association failed campaigns`}
                    role="link"
                  >
                    <SmallNumber isAlert>{data.alerts[CampaignAlert.StopAssociationFailed]}</SmallNumber>
                    <AlertLabel>
                      <Icon name="dashboardAlert" width={23} height={23}></Icon>
                      {CampaignAlertLabel[CampaignAlert.StopAssociationFailed]}
                    </AlertLabel>
                  </StatusSmallBox>
                </StatusRow>
              </CampaignSection>
              <NoRedemptionsSection aria-label='No Redemption Section' data-automation-id="active-campaigns-without-redemptions">
                <StatusRow>
                  <RedemptionsHeader>
                    Active Campaigns{' '}
                    <RedemptionsHeaderSpan>
                      (without Redemptions in the past {config.noRedemptionsNotificationThreshold} hours)
                    </RedemptionsHeaderSpan>
                  </RedemptionsHeader>
                </StatusRow>
                <NoRedemptionTableContainer>
                  <NoRedemptionHeader
                    onClick={() =>
                      openCampaignsById(
                        data.campaigns.noRedemptions.data.campaigns.map((campaign) => campaign.externalId),
                      )
                    }
                    onKeyDown={(event) =>
                      handleKeyPress(event, () =>
                        openCampaignsById(
                          data.campaigns.noRedemptions.data.campaigns.map((campaign) => campaign.externalId),
                        ),
                      )
                    }
                    tabIndex={0}
                    data-automation-id="active-campaigns-without-redemptions-total"
                    role="link"
                  >
                    <NoRedemptionAlertLabel>
                      <NoRedemptionAlert>
                        <SmallNumber isAlert cursor="pointer">
                          {data.campaigns.noRedemptions.data.total}
                        </SmallNumber>
                        <NoRedemptionAlertLabelSpan>Total Campaigns</NoRedemptionAlertLabelSpan>
                      </NoRedemptionAlert>
                      {data.campaigns.noRedemptions.lastUpdated && (
                        <LastUpdated align="right">
                          Last Updated:{' '}
                          {formatDate(
                            data.campaigns.noRedemptions.lastUpdated,
                            { dateFormat, timeFormat },
                            startTimezone,
                          )}
                        </LastUpdated>
                      )}
                    </NoRedemptionAlertLabel>
                  </NoRedemptionHeader>

                  <Table
                    tableProps={{
                      ...getDashboardCampaignsTableProps(onCampaignNameClick),
                      rows: data.campaigns.noRedemptions.data.campaigns,
                    }}
                    data-automation-id="active-campaigns-without-redemptions-table"
                  />
                </NoRedemptionTableContainer>
              </NoRedemptionsSection>
            </CampaignsOverview>
          </InnerBoard>
          <Column>
            <RedemptionOverview
              redemptions={data.redemptions ?? defaultDashboardData.data.redemptions}
            ></RedemptionOverview>
            <OffersOverview aria-label='Offers Overview' data-automation-id="offers-overview">
              <SideOverviewHeader>Offers Overview</SideOverviewHeader>
              <SideOverviewContent data-automation-id="offers-overview-rejected">
                <OffersOverviewSmallBox
                  color="grey"
                  tabIndex={0}
                  borderColor="yellow"
                  onClick={() => openOfferStatus(ApprovalStatus.Rejected)}
                  onKeyDown={(event) => handleKeyPress(event, () => openOfferStatus(ApprovalStatus.Rejected))}
                  role="link"
                >
                  <SmallNumber>{data.offers[ApprovalStatus.Rejected]}</SmallNumber>
                  <span>{ApprovalStatusLabel[ApprovalStatus.Rejected]}</span>
                </OffersOverviewSmallBox>
                <OffersOverviewSmallBox
                  color="grey"
                  tabIndex={0}
                  borderColor="yellow"
                  onClick={() => openOfferStatus(ApprovalStatus.PendingApproval)}
                  onKeyDown={(event) => handleKeyPress(event, () => openOfferStatus(ApprovalStatus.PendingApproval))}
                  data-automation-id="offers-overview-pending-approval"
                  role="link"
                >
                  <SmallNumber>{data.offers[ApprovalStatus.PendingApproval]}</SmallNumber>
                  <span>{ApprovalStatusLabel[ApprovalStatus.PendingApproval]}</span>
                </OffersOverviewSmallBox>
              </SideOverviewContent>
              {data.offers.lastUpdated && (
                <LastUpdated>
                  Last Updated: {formatDate(data.offers.lastUpdated, { dateFormat, timeFormat }, startTimezone)}
                </LastUpdated>
              )}
            </OffersOverview>
            <UpdatesOverview updates={data.updates} routeToSpecificPath={routeToSpecificPath}></UpdatesOverview>
          </Column>
        </WrapperBoard>
      </Board>
    </PageContainer>
  );
};

export default Dashboard;
