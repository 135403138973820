import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Icon, IconProps } from 'components/shared/icon';
import ProgressIndicator from 'pages/shared/progressIndicator/ProgressIndicator';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 4}px ${typography.unit * 7}px;
  height: 100%;
  overflow: auto;
`;

export const PeriodName = styled.div`
  display: inline;
  margin-right: ${typography.unit * 4}px;
`;

export const ScheduleTable = styled.div<{ hasPartialApproved?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasPartialApproved }) => (hasPartialApproved ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)')};
  grid-template-rows: 35px auto;
  margin-top: ${typography.unit * 5}px;
  height: 100%;
  overflow: auto;
`;

export const ScheduleTableHeader = styled.div<StyledWithThemeProps>`
  font-weight: 500;
  border-top: 1px solid ${({ theme }) => theme.colors.global.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  border-right: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  align-items: center;
  padding-left: ${typography.unit}px;

  &:nth-child(4) {
    border-right: none;
  }
`;

export const ScheduleTableData = styled.div<StyledWithThemeProps>`
  border-right: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: 0 ${typography.unit}px;
  overflow: auto;

  &:last-child {
    border-right: none;
  }
`;

export const TableItem = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  max-width: 60%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: ${typography.unit * 2}px;
`;

export const IconTooltipWrapperPlaceholder = styled.div`
  display: flex;
  margin-left: ${typography.unit}px;
  width: 16px;
`;

export const AlertIcon = styled(Icon)<IconProps>`
  width: 16px;
  fill: ${({ theme }) => theme.colors.global.error};
`;

export const StyledProgressIndicator = styled(ProgressIndicator)`
  margin-top: ${typography.unit}px;
`;
export const LockSpace = styled.span<{width:number, height: number}>`
  background: transparent;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
