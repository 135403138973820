import styled from '@emotion/styled';
import MultipleChoice from 'components/shared/multipleChoose/MultipleChoice';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { DateTimePicker } from 'components/shared/picker/datetimePicker/DateTimePicker';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from 'components/shared/icon';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import NumberTextField from 'components/shared/textField/NumberTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 530px;
  width: 530px;
`;

export const MultipleDateRow = styled.div`
  display: flex;
  width: 257px;
  margin: 0;
  margin-right: 8px;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
  width: 530px;
`;

export const RowSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 530px;
  height: 24px;
  margin-bottom: ${typography.unit * 5}px;
`;

export const HeadingRowSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 530px;
  height: 24px;
  margin-bottom: ${typography.unit * 5}px;
`;

export const RedemptionRow = styled.div`
  width: 530px;
  margin-bottom: 16px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  margin-right: ${typography.unit * 5}px;
  width: 257px;

  [data-automation-id~='error-message'] {
    font-size: 10px;
    margin-top: ${typography.unit * 5}px;
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  margin-right: ${typography.unit * 5}px;
  width: 257px;
  .error-message {
    font-size: 14px; // Example: changing font size
    color: black; // Change the color of the error message
    margin-top: 8px; // Adjust margin if necessary
  }
`;

export const ScheduleContainer = styled.div<{ hasExtraMargin?: boolean }>`
  display: flex;
  margin-bottom: ${({ hasExtraMargin }) => (hasExtraMargin ? `${typography.unit * 7}px` : `${typography.unit * 3}px`)};

  div:last-of-type {
    margin-right: 0;
  }
`;

export const StyledMultipleChoice = styled(MultipleChoice)`
  margin-right: ${typography.unit * 5}px;
`;

export const ExpirationDate = styled(TextField)`
width:257px;
  input {
    width: 50px;
  }
`;

export const RecurrenceRow = styled.div`
  width: 530px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubHeader = styled.div`
  width: 530px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  gap: 0px;
  opacity: 0px;
  display: flex;
  margin-bottom: ${typography.unit}px;
`;

export const CampaignSubHeader = styled.div`
  gap: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  opacity: 0px;
  display: flex;
  margin-bottom: ${typography.unit}px;
`;

export const MultipleDatesDiv = styled.div``;
export const ToggleTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;

  margin-right: ${typography.unit * 2}px;
`;

export const RecurrenceSelect = styled(Selectbox)`
  margin-right: 16px;
`;

export const Redemption = styled.div<StyledWithThemeProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.text.primary};
  align-items: center;
`;

export const NumberField = styled(NumberTextField)`
  margin-right: 16px;
  width: 195px;
  label {
    font-family: Speedee;
    font-size: 12px;
    font-weight: 700;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .input-wrapper {
    width: 195px;
    border-color: ${newGlobalTheme.colors.global.borderColorboxes};
  }
`;

export const RedemptionNumberField = styled(NumberTextField)`
  margin-right: 23px;
  label {
    margin-right: 23px;
    font-family: Speedee;
    font-size: 12px;
    font-weight: 500;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .input-wrapper {
    width: 105.5px;
    border-color: ${newGlobalTheme.colors.global.borderColorboxes};
  }
`;

export const RedemptionsText = styled.span<StyledWithThemeProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-right:40px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const AddLimitText = styled.span<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.textSubheading};
  margin-left: 8px;
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
  fill: ${newGlobalTheme.colors.global.textSubheading};
  margin: ${typography.unit * 4}px 0;
  align-self: center;
`;

export const StyledRemoveIcon = styled(StyledIcon)`
`;

export const StyledEditIcon = styled(StyledIcon)`
  margin: 0 ${typography.unit * 3}px 0 0;
`;

export const BulkSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${typography.unit}px;
`;

export const MultipleDatesToggle = styled(ToggleSwitch)`
  margin-bottom: ${typography.unit}px;
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  align-items: center;
  font-family: Speedee;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
`;

export const MultipleDatesSection = styled.div`
  display: flex;
  width: 530px;
  align-items: center;
  margin-bottom: ${typography.unit * 3}px;
`;

export const DatesSelection = styled.div`
  display: flex;
  width: 148px;
  align-items: center;
  margin-left: 16px;
`;

export const DurationSelection = styled.div`
  width: 257px;
  align-items: left;
  display: flex;
`;
