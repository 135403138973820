import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import { Selectbox } from 'components/shared/selectbox/Selectbox';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VoucherConfigRow = styled.div`
  display: flex;
  margin-bottom:16px;
  min-height: 59px;

`;

export const IwinRow = styled.div`
  display: flex;
  flex-direction:row;
  margin-bottom:16px;
  min-height: 59px;
`;

export const GamingTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left:16px;
`;

export const Quantity = styled(TextField)`
  width: 257px;
`;

export const StyledToggleSwitch = styled(ControlledToggleSwitch)`
  margin-right: ${typography.unit * 6}px;
  margin-top: 6px;
font-size: 12px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;

`;

export const StyledSelectBox = styled(Selectbox)`
margin-left:8px;
margin-right:16px;
`;

export const IwinStyledSelectBox = styled(Selectbox)`
margin-right:16px;
`;
