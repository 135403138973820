import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { ButtonBaseProps, NewButtonBaseProps } from './ButtonBase.consts';

export const StyledButton = styled.button<ButtonBaseProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  text-decoration: none;
  border-radius: ${typography.borderRadius}px;
  padding: 6px 16px;
  font-weight: 400;
  font-family: ${typography.primaryFont};
  font-size: ${typography.fontSize}px;
  white-space: nowrap;
  transition: all ${typography.transition}s;
`;

export const StyledNewButton = styled.button<NewButtonBaseProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  text-decoration: none;
  border-radius: ${newTypography.borderRadius}px;
  padding: 6px 16px;
  font-weight: 700;
  font-family: ${newTypography.primaryFont};
  font-size: ${newTypography.fontSize}px;
  white-space: nowrap;
  transition: all ${newTypography.transition}s;
`;
