import styled from '@emotion/styled';
import { NewButtonDropdown } from 'components/shared/button/buttonDropdown';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const StyledButtonDropdown = styled(NewButtonDropdown)<StyledWithThemeProps>`
  height: 32px;
  width: 56px;
  padding: 0;
  font-family: ${newTypography.primaryFont};
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  font-weight: 400;

  button {
    padding: 0 6px;
  }
`;
