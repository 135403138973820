import React, { useEffect } from 'react';
import { ApprovalStatusLabel, ApprovalStatusWithLabel, GenericEntity, OrderDirection } from 'utils/types';
import { OfferTemplates, OfferApprovalStatuses } from 'utils/types/offers';
import { useSelector } from 'react-redux';
import { useUrlFilters } from 'hooks/use-url-filters';
import { store } from 'app/store';
import { offersPage } from 'app/genericSlices/offers';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { useQuery } from '@apollo/client';
import { LocationSet } from 'utils/types/locations';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { FetchPolicies } from 'utils/types/common';
import { NewStyledFilterBarSelect, OfferFieldLabel, OfferFilterBarContainer, OfferFilterRow, NewOfferFilters, OfferSearchField, OfferNewSearchTextField } from './OfferFilterBar.style';
import { OfferFilters } from 'pages/offers/Offers.const';

export const OfferFilterBar = () => {
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      offersPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);
  const { filters } = useSelector(offersPage.offersState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[OfferFilters.SearchQuery]);

  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
    loadTags();
  }, []);

  return (
    <OfferFilterBarContainer tabIndex={-1}>
      <NewOfferFilters tabIndex={-1}>
        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Template</OfferFieldLabel>
          <NewStyledFilterBarSelect
            placeholder="Select"
            name="template"
            multiple
            items={Object.values(OfferTemplates) as any[]}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.TemplateType,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[OfferFilters.TemplateType]}
            reset
            maxItems={1}
            selectWidth={225}
          />
        </OfferFilterRow>

        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Status</OfferFieldLabel>
          <NewStyledFilterBarSelect
            placeholder="Select"
            name="status"
            multiple
            items={
              OfferApprovalStatuses.map((status) => ({
                id: status,
                name: ApprovalStatusLabel[status as ApprovalStatusWithLabel],
              })) as any[]
            }
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.Status,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[OfferFilters.Status]}
            reset
            limit={5}
            maxItems={1}
            selectWidth={225}
          />
        </OfferFilterRow>

        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Tags</OfferFieldLabel>
          <NewStyledFilterBarSelect
            key={`${Boolean(tags.length)}`}
            placeholder="Select"
            name="tags"
            multiple
            maxItems={1}
            items={tags}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.Tags,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={tags?.length ? filters[OfferFilters.Tags] : []}
            reset
            withAmount
            withSearch
            selectWidth={225}
          />
        </OfferFilterRow>

        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
        >
          <OfferFilterRow tabIndex={-1}>
            <OfferFieldLabel tabIndex={0}>Zone</OfferFieldLabel>
            <NewStyledFilterBarSelect
              key={`${JSON.stringify(zonesData?.getLocationSets?.items)}`}
              placeholder="Select"
              name="zone"
              multiple
              items={zonesData?.getLocationSets?.items}
              onChange={(selectedItems: any) => {
                urlFilters.filter(
                  OfferFilters.Zone,
                  Object.values(selectedItems).map((i: any) => i.id),
                );
              }}
              initialSelectedItems={zonesData?.getLocationSets?.total ? filters[OfferFilters.Zone] : []}
              reset
              withSearch
              maxItems={1}
              selectWidth={225}
            />
          </OfferFilterRow>
        </MarketConfigurationGuard>

        <OfferFilterRow tabIndex={-1}>
          <OfferSearchField tabIndex={-1}></OfferSearchField>
          <OfferNewSearchTextField
            key={`${JSON.stringify(filters[OfferFilters.SearchQuery])}_SearchQuery`}
            name="offers-search"
            caretPosition={caretPosition}
            value={filters[OfferFilters.SearchQuery] ?? ''}
            onChange={(e) => {
              urlFilters.filter(OfferFilters.SearchQuery, e.target.value);
              setCaretPosition(e);
            }}
          />
        </OfferFilterRow>
      </NewOfferFilters>
    </OfferFilterBarContainer>

  );
};
