import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { CheckboxContainerProps, StyledCheckboxProps, StyledLabelProps } from './Checkbox.consts';
import { Icon, IconProps } from '../icon';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  line-height: 15px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckmark = styled(Icon)<IconProps>`
  fill: ${newGlobalTheme.colors.global.headerBackground};
  width: 16px;
`;

export const StyledIntermediate = styled(Icon)<IconProps>`
  fill: ${newGlobalTheme.colors.global.brandMain};
  width: 16px;
`;

export const StyledLabel = styled.label<StyledLabelProps & { disabled: boolean }>`
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-left: ${typography.unit * 2}px;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  cursor: pointer;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: initial;

`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-color: ${({ checked, intermediate, disabled, theme }) =>
    checked || intermediate
      ? disabled
        ? newGlobalTheme.colors.global.disabledGold
        : newGlobalTheme.colors.global.brandMain
      : theme.colors.checkbox.uncheckedBg};
  border: 1px solid
    ${({ checked, intermediate, disabled, theme }) =>
      checked || intermediate
        ? newGlobalTheme.colors.button.border.secondary
        : disabled
        ? theme.colors.global.inputDisabled
        : newGlobalTheme.colors.global.borderColorboxes};
  border-radius: 3px;
  transition: all 150ms;
  ${({offerTemplate, checked, disabled}) => offerTemplate && checked && disabled && `
    border: 1px solid ${newGlobalTheme.colors.global.disabledGold};
`}
  ${({offerTemplate, checked, disabled}) => offerTemplate && !checked && disabled && `
      border: 1px solid ${newGlobalTheme.colors.status.archived};
      background-color: ${newGlobalTheme.colors.status.archived};
  `}
}
`;
