import { createTheme } from '@mui/material/styles';

const redColorString = 'rgba(218, 30, 40, 0.3)';

export const defaultTheme = {
  name: 'Default',
  colors: {
    global: {
      background: '#FFF',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      error: '#DA1E28',
      success: '#24A148',
      warning: '#F99B58',
      border: '#C4C5C8',
      inputPrimary: '#1F5CF6',
      inputDisabled: '#DDDEE1',
      brandMain: '#FFCC05',
      brandBackdrop: 'rgba(255, 204, 5, 0.15)',
      headerBackground: 'linear-gradient(#FFF 65%, #e5e5e5)',
      imageBackground: '#F5F5F6',
      selectedBackground: '#F1F2F3',
      boxShadow: '0 8px 25px 0 rgba(0, 0, 0, 0.22)',
      backgroundRGBA: 'rgba(0, 0, 0, 0)',
    },
    text: {
      primary: '#303132',
      disabled: '#9D9EA1',
      secondary: '#5C5D60',
      white: '#FFF',
    },
    tabStrip: {
      backgroundHover: 'rgba(0, 0, 0, 0.03)',
    },
    button: {
      hover: '#1243BE',
      disabled: 'rgba(31, 92, 246, 0.4)',
      approve: {
        primary: 'rgba(36, 161, 72, 0.3)',
        disabled: 'rgba(36, 161, 72, 0.15)',
        hover: 'rgba(36, 161, 72, 0.7)',
      },
      reject: {
        primary: redColorString,
        hover: 'rgba(218, 30, 40, 0.7)',
      },
      revoke: {
        primary: 'rgba(249, 155, 88, 0.5)',
        hover: 'rgba(249, 155, 88, 0.7)',
      },
    },
    checkbox: {
      iconColor: '#FFF',
      uncheckedBg: '#FFF',
      disabledCheckedBg: 'rgba(31, 92, 246, 0.5)',
    },
    notifications: {
      primary: {
        natural: '#9D9EA1',
        info: '#1F5CF6',
        warning: '#F99B58',
        success: '#24A148',
        error: '#DA1E28',
      },
      background: {
        natural: '#F1F2F3',
        info: '#E9EFFF',
        warning: '#FEEDE2',
        success: '#E4F3E9',
        error: '#FCE9EA',
      },
    },
    status: {
      draft: 'transparent',
      pendingApproval: 'rgba(255, 188, 13, 0.3)',
      approved: 'rgba(36, 161, 72, 0.2)',
      deployed: 'rgba(36, 161, 72, 0.3)',
      active: 'rgba(36, 161, 72, 0.6)',
      expired: 'rgba(196, 197, 200, 0.9)',
      revoked: 'rgba(249, 155, 88, 0.5)',
      deploymentFailed: redColorString,
      rejected: redColorString,
      archived: '#DDDEE1',
      partiallyApproved: 'rgba(36, 161, 72, 0.2)',
    },
    dashboard: {
      green: 'rgba(189, 227, 200, 1)',
      red: redColorString,
      yellow: 'rgba(255, 188, 13, 0.3)',
      blue: 'rgba(31, 92, 246, 0.2)',
    },
    events: {
      local: '#c3baea',
      national: '#8fe0fa',
    },
  },
  ...createTheme(),
};

export type appTheme = typeof defaultTheme;
