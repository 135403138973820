import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import typography, { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonClose, NewButtonText } from '../button';
import TextArea from '../textArea/TextArea';
import { NewButtonBase } from '../button/buttonBase/ButtonBase';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledTooltip = styled(ReactTooltip)<StyledWithThemeProps>`
  padding: ${typography.unit * 4}px !important;
  border: ${({ theme }) => `1px solid ${theme.colors.global.border} !important`};
  border-radius: ${typography.borderRadius}px;
  background: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.tooltip};
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  text-align: start;
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: max-content;
  pointer-events: auto !important;

  &.show {
    opacity: 1 !important;
    white-space: pre-wrap;
  }

  &[data-id='tooltip'] {
    &.place-bottom {
      margin-top: 15px;

      &::before {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          background-color: transparent;
          border-bottom: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          margin-left: -10px;
          top: -11px;
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -10px;
          top: -10px;
      }
    }

    &.place-top {
      margin-top: -15px;

      &::before {
         width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          background-color: transparent; 
          border-top: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          margin-left: -10px;
          bottom: -11px; 
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -10px;
          bottom: -11px;
      }
    }

    &.place-right {
      margin-left: 20px;

      &::before {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          transform: none;
          background-color: transparent;
          margin-left: -3px;
          margin-top: -9px; 
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -4px;
          margin-top: -9px;
      }
    }

    &.place-left {
      margin-left: -20px;

      &::before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
        transform: none;
        background-color: transparent;
        margin-right: -3px;
        z-index: -2;
        margin-top: -9px;
      } 

      &::after {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
        transform: none;
        background-color: transparent;
        margin-right: -4px;
        margin-top: -9px;
      }
    }
  }
`;

export const StyledButtonClose = styled(ButtonClose)`
  position: absolute;
  right: 0;
  padding: 0;
  height: 8px;
  width: 8px;
`;

export const StyledButtonText = styled(NewButtonText)`
  color: #006BAE;
  font-size: 14px;
  font-weight: 700;
`;

export const NewStyledApproveButton = styled(NewButtonBase)`
  background-color: ${newGlobalTheme.colors.button.new.primary};
  border: 1px solid ${newGlobalTheme.colors.button.new.border};
  height: 32px;
  font-size: 14px;
  border-radius:5px;

  &:hover {
    border-color: ${newGlobalTheme.colors.global.tabNavigation};
  }

  &:focus-visible {
    outline: 1px solid ${newGlobalTheme.colors.global.tabNavigation};
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }

  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    background-color: ${newGlobalTheme.colors.global.disabledGold};
  }
`;

export const StyledContent = styled.div<{ shouldMargin: boolean } & StyledWithThemeProps>`
  padding-right: ${({ shouldMargin }) => shouldMargin && `${typography.unit * 3}px`};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-family: ${newTypography.primaryFont};
  // text-overflow: ellipsis;
  // overflow: hidden;
  overflow-wrap: break-word;
  display: block;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${typography.unit * 2}px;

  button:first-of-type {
    margin-right: ${typography.unit * 5}px;
  }
`;

export const StyledTextArea = styled(TextArea)``;
