import React, { useEffect, useState } from 'react';
import  { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { Loader } from 'components/shared/loader';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import { FormMode, OrderDirection } from 'utils/types';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { BaseOffer } from 'utils/types/offers';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { offersPage } from 'app/genericSlices/offers';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import useQueryInterval from 'hooks/use-query-polling';
import { ActionButtonsContainer, NewOffersGridContainer, OfferAddNewButton, OfferFilterHeader, OfferNewStyledHeader, OfferPageContainer, OfferPageTitle, OffersGrid, OfferTitleActionHeader, TitleActionButtonsContainer, ZonesButton, ZonesIcon } from './Offers.style';
import { StyledLoader } from '../shared/shared.style';
import OfferItem from './components/offerItem/OfferItem';
import { OfferFilterBar } from './components/offerFilterBar/OfferFilterBar';
import offersGqls from './Offers.gqls';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import NewAddNewButton from 'pages/shared/addNewButton/NewAddNewButton';
import { StyledTotalAmountWithGrid } from './components/offerFilterBar/OfferFilterBar.style';

const Offers = () => {
  const { filters } = useSelector(offersPage.offersState);
  const [isOfferArchieving, setIsOfferArchieving] = useState(false);
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(offersGqls.queries.getOffersScreenData, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: offersPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
      },
    },
  });
  const {
    getOffers: { items: offers, total },
  } = data || { getOffers: { items: undefined } };
  useToastError(error, 'Error loading offers');
  useQueryInterval(600000, startPolling, stopPolling);

  const onCreateNewOffer = () => {
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { mode: FormMode.New },
      }),
    );
  };

  const onZonesButtonClick = () => {
    store.dispatch(
      openModal({
        modal: Modals.ZonesManagementModal,
      }),
    );
  };

  const fetchNextOffers = async () => {
    if (offers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: offersPage.getFilters(filters),
            offset: offers.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
          },
        },
      });
    }
  };

  useEffect(() => {
    store.dispatch(termsConditionsSelection.loadTerms());
  }, []);
  return (
    <OfferPageContainer>
      <OfferNewStyledHeader data-automation-id="header">
        <OfferTitleActionHeader>
          <OfferPageTitle text={tabName.Offers} tabIndex={0}/>
          <TitleActionButtonsContainer data-automation-id="actions">
            <ActionButtonsContainer>
              <MarketConfigurationGuard
                configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
              >
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  <ZonesButton onClick={onZonesButtonClick} tabIndex={0} aria-label="Offer Zones Button">
                    <ZonesIcon name="newMapPin" ></ZonesIcon>
                  </ZonesButton>
                </RoleGuard>
              </MarketConfigurationGuard>
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                <OfferAddNewButton onClick={onCreateNewOffer} aria-label="Add New Offer" />
              </RoleGuard>
            </ActionButtonsContainer>
          </TitleActionButtonsContainer>
        </OfferTitleActionHeader>
        <OfferFilterHeader>
          
          <OfferFilterBar />
        </OfferFilterHeader>
        <StyledTotalAmountWithGrid amount={total} aria-label="Total Offer {total}" tabIndex={0} />
      </OfferNewStyledHeader>

      {!offers ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>

          {Boolean(offers?.length) && (
            <OffersGrid id="offers-scroll">
              {isOfferArchieving && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <NewOffersGridContainer
                scrollableTarget="offers-scroll"
                dataLength={offers?.length}
                next={fetchNextOffers}
                hasMore={offers?.length < total}
                loader={<Loader />}
              >
                {offers?.map((offer: BaseOffer) => {
                  return <OfferItem key={offer.id} offer={offer} setIsOfferArchieving={setIsOfferArchieving} />;
                })}
                </NewOffersGridContainer>
              <Tooltip id={"offer-lock-icon"} onHover content="Locked for changes" place="left" />
            </OffersGrid>
          )}
        </>
      )}
    </OfferPageContainer>
  );
};

export default Offers;
