import React from 'react';
import { CheckboxProps } from './Checkbox.consts';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckmark,
  StyledIntermediate,
  StyledLabel,
} from './Checkbox.style';

const getUEID = () => {
  return Math.random().toString(32).slice(2);
};

const Checkbox = ({ checked, intermediate, disabled, label, className, onClick, id, offerTemplate }: CheckboxProps) => {
  const generatedId = getUEID();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {

    if (e.key === 'Enter' && !disabled) {
      onClick(e as unknown as React.MouseEvent<HTMLDivElement>);
    }
  };

  return (
    <CheckboxContainer
      className={className}
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      data-automation-id="checkbox-container"
    >
      <HiddenCheckbox
        type="checkbox"
        id={id || generatedId}
        defaultChecked={checked}
        disabled={disabled}
        tabIndex={-1}      />
      <StyledCheckbox
        checked={checked}
        intermediate={intermediate}
        disabled={disabled}
        data-automation-id="checkbox"
        tabIndex={0}
        offerTemplate={offerTemplate}
      >
        {checked && <StyledCheckmark name="checkmark" />}
        {intermediate && <StyledIntermediate name="minus" />}
      </StyledCheckbox>
      {label && (
        <StyledLabel htmlFor={id || generatedId} disabled={disabled}>
          {label}
        </StyledLabel>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
