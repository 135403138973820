import React from 'react';
import { toast, Slide } from 'react-toastify';
import { StyledToastContainer } from './ToastContainerWrapper.style';
import { MessageType } from '../notifications';
import Toast from '../toast/Toast';
import { useSelector } from 'react-redux';
import { modal as modalSlice } from 'app/slices/modals';

const ToastContainerWrapper = () => {
  const {modal} = useSelector(modalSlice);

  return (
    <StyledToastContainer
      position="top-center"
      hideProgressBar
      newestOnTop
      pauseOnFocusLoss
      pauseOnHover
      closeOnClick={false}
      modalOpen={modal ? true : false}
    />
  );
};

export const showToast = (type: MessageType, msg: string, staticImport?: boolean) => {
  toast(<Toast type={type} staticImport={staticImport}>{msg}</Toast>, {
    autoClose: type === MessageType.Error ? false : 5000,
    transition: Slide,
    toastId: msg,
  });
};

export default ToastContainerWrapper;
