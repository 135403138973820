import React from 'react';
import { ButtonBaseProps, NewButtonBaseProps } from '../buttonBase/ButtonBase.consts';
import { StyledAddNewButtonBase, StyledBaseSave, StyledButtonBase, StyledButtonBaseApprove, StyledButtonBaseCancel, StyledButtonBaseReject, StyledButtonBaseSave, StyledButtonBaseSaveAs, StyledButtonText, StyledNewButtonBase } from './ButtonContained.style';

export const ButtonContained = (props: ButtonBaseProps) => {
  return <StyledButtonBase {...props} />;
};

export const HeaderButton = (props: ButtonBaseProps) => {
  return <StyledButtonText {...props} />;
};

export const ButtonContainedSave = (props: ButtonBaseProps) => {
  return <StyledButtonBaseSave {...props} />;
};

export const ButtonContainedCancel = (props: ButtonBaseProps) => {
  return <StyledButtonBaseCancel {...props} />;
};

export const ButtonContainedReject = (props: ButtonBaseProps) => {
  return <StyledButtonBaseReject {...props} />;
};



export const ButtonContainedSaveAs = (props: ButtonBaseProps) => {
  return <StyledButtonBaseSaveAs {...props} />;
};

export const Approve = (props: ButtonBaseProps) => {
  return <StyledButtonBaseApprove {...props} />;
};

export const Save = (props: ButtonBaseProps) => {
  return <StyledBaseSave {...props} />;
};




export const NewButtonContained = (props: NewButtonBaseProps) => {
  return <StyledNewButtonBase {...props} />;
};

export const AddNewButtonContained = (props: NewButtonBaseProps) => {
  return <StyledAddNewButtonBase {...props} />;
};
