import React from 'react';
import { ButtonBaseProps, NewButtonBaseProps } from '../buttonBase/ButtonBase.consts';
import { NewStyledButtonBase, NewStyledClose, StyledButtonBase, StyledClose } from './ButtonClose.style';

export const ButtonClose = (props: ButtonBaseProps) => {
  return (
    <StyledButtonBase {...props}>
      <StyledClose name="close" />
    </StyledButtonBase>
  );
};

export const NewButtonClose = (props: NewButtonBaseProps) => {
  return (
    <NewStyledButtonBase {...props}>
      <NewStyledClose height={20} width={20} name="newClose" />
    </NewStyledButtonBase>
  );
};

export const NewButtonCloseInSelect = (props: NewButtonBaseProps) => {
  return (
    <NewStyledButtonBase {...props}>
      <NewStyledClose name="newCloseSelect" />
    </NewStyledButtonBase>
  );
};
