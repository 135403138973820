import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { OfferModalProps } from './Modal.const';
import { OfferMainTitle, OfferContent, OfferModalHeader, OfferContainer } from './OfferModal.style';
import { Loader } from '../loader';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { StyledButtonDropdown } from 'pages/offers/components/offerForm/OfferForm.style';
import { DraftOfferActionsList, OfferActions, OfferActionsList } from 'utils/types/offers';
import { FormMode } from 'utils/types';
import {  Loading } from './NewModal.style';

export const OfferModal = ({
  children,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  contentWidth,
  onActionClicked,
  modalMode,
  status,
  showMoreActions,
  ...rest
}: OfferModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const headerRef = useRef(null);

  useEffect(() => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
  }, [modalMode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <OfferContainer className={className} data-automation-id="modal" {...rest}>
        {withLoader &&  (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
          <><OfferModalHeader className='ModalHeader' >
            <OfferMainTitle  ref={headerRef} tabIndex={0}>
              {title && <NewTextModalTitle text={title} />}
              {isLocked && <LockIcon />}
              {modalMode === FormMode.View &&
                  <>
                  {showMoreActions &&
                    <StyledButtonDropdown
                    version='offer'
                    items={status === 'draft' ? Object.values(DraftOfferActionsList) as any[] : Object.values(OfferActionsList) as any[]}
                    onItemClick={(item: any) => onActionClicked(item.name)}
                    position='bottom-left'
                    >
                    </StyledButtonDropdown>
                  }
                <RoleGuard roles={[UserRole.Viewer]}>
                      <StyledButtonDropdown
                      items={[{ id: OfferActions.ViewImpact, name: OfferActions.ViewImpact }] as any[]}
                      onItemClick={(item: any) => onActionClicked(item.name)}
                      position='bottom-left'
                      >
                    </StyledButtonDropdown>
                </RoleGuard>
            </>
          }
          </OfferMainTitle>
            {subtitle ?? null}
        </OfferModalHeader>
        <OfferContent
            contentWidth={contentWidth}
            data-automation-id="modal-content">
              {children}
          </OfferContent>
        </>
      </OfferContainer>
    </>,
    document.querySelector('#modal'),
  );
};

export default OfferModal;
