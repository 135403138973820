import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import TextField from '../textField/TextField';

export const StyledNewTextField = styled(TextField)`
  font-family: ${newTypography.primaryFont};

  & input {
    background: ${newGlobalTheme.colors.text.backgroundColor};
    height: 60px;
    font-size: ${newTypography.fontSize}px;
    font-family: ${newTypography.primaryFont};
    padding-left: 21px;
    color: ${newGlobalTheme.colors.text.primary};
  }

  .input-wrapper {
    &:hover {
      border-bottom: 1px solid #292929;
    }

    &:focus-within {
      border-bottom: 1px solid rgba(41, 41, 41, 1);
    }
  }
`;

export const StyledNewSearchTextField = styled(TextField)`
  font-family: ${newTypography.primaryFont};
  & input {
    font-size: ${newTypography.smallFontSize}px;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.text.primary};
  }

  .input-wrapper {
    background: ${newGlobalTheme.colors.text.backgroundColor};
    &:hover {
      border-bottom: 1px solid #292929;
    }

    &:focus-within {
      border-bottom: 1px solid rgba(41, 41, 41, 1);
    }
  }
`;
