import styled from '@emotion/styled';
import typography from 'styles/typography';
import {  NewButtonText } from '../button';
import { IconProps, Icon } from '../icon';
import NewChip from '../chip/NewChip';
import NewTooltip from '../tooltip/NewTooltip';

export const NewStyledTags = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
`;

export const NewTagsList = styled.div`
  display: flex;
  flex: 0 1;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 45%;
  max-width: 65%;
`;

export const NewStyledMore = styled(Icon)<IconProps>`
  height: 14px;
  width: 14px;
  transform: rotate(90deg);
  color: white;
`;

export const NewStyledButtonText = styled(NewButtonText)`
  height: 22px;
  margin-left: ${typography.unit * 2}px;
`;

export const NewStyledChip = styled(NewChip)`
  margin-top: ${typography.unit}px;
  max-width: fit-content;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const NewStyledTooltip = styled(NewTooltip)`
  min-height: 20px; 
  max-height: 200px;
  &[data-id='tooltip'] {
    &::before,
    &::after {
      display: none;
    }

    &.place-bottom {
      margin-top: 0;
    }
  }
`;
